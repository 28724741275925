/*** IMPORTS FROM imports-loader ***/
var define = false;

//import $ from 'jquery';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

let imagesLoaded = require('imagesloaded');
let MobileDetect = require('mobile-detect');
let detect = require('detect');
let cookiebar = require('cookiebar');

//Scrollbar.init(document.querySelector('main'));

let md = new MobileDetect(window.navigator.userAgent);
let ua = detect.parse(navigator.userAgent);
// console.log("mobile");
// console.log(md.mobile()); // 'Sony'
// console.log(md.phone()); // 'Sony'
// console.log(md.tablet()); // null
// console.log(md.userAgent()); // 'Safari'
// console.log(md.os()); // 'AndroidOS'
// console.log(md.is("iPhone")); // false
// console.log(md.is("bot")); // false
// console.log(md.version("Webkit")); // 534.3
// console.log(md.versionStr("Build")); // '4.1.A.0.562'
// console.log(md.match("playstation|xbox")); // false

// console.log("detect");
// console.log(ua.browser);
// console.log(ua.device);
// console.log(ua.os);
//test 2
let w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight || e.clientHeight || g.clientHeight;

let scrollI = document.getElementById("scrollIcon");
let ismobile = false;

if (md.mobile())
    ismobile = true;


// Define the Function targetBlank()
function targetBlank() {
    // remove subdomain of current site's url and setup regex
    let internal = location.host.replace("www.", "");
    internal = new RegExp(internal, "i");

    let a = document.getElementsByTagName('a'); // then, grab every link on the page
    for (let i = 0; i < a.length; i++) {
        let href = a[i].host; // set the host of each link
        if ((!internal.test(href) && !/^javascript/.test(a[i].getAttribute("href"))) || /\.pdf$/.test(a[i].getAttribute("href"))) { // make sure the href doesn't contain current site's host
            a[i].setAttribute('target', '_blank'); // if it doesn't, set attributes
        }
    }
}

(function () {
    "use strict";
    console.log('JS loaded');
    // Run the function targetBlank()
    targetBlank();

    const navbar = document.querySelector('.navbar');
    const navbartoggle = document.querySelector('button.navbar-toggler');

    if (document.querySelectorAll(".scroll-top").length > 0) {
        document.querySelectorAll('.scroll-top')[0].addEventListener("click", function (event) {
            window.scrollTo({
                'behavior': 'smooth',
                'left': 0,
                'top': 0
            });
        });
    }
    if (document.querySelectorAll("button.navbar-toggler:not(.kontakt)").length > 0) {
        // Mobile toggler
        navbartoggle.addEventListener("click", function (event) {
            let target = document.querySelectorAll('.navbar-collapse')[0];
            if(!navbartoggle.classList.contains('open')){
                $(navbartoggle).addClass('open');
                $(target).addClass('show');
                $(navbar).addClass('open');
                $('.navbar .nav-item').removeClass('open');
                $('html').addClass('disable-scroll');
            }else{
                $(navbartoggle).removeClass('open');
                $(target).removeClass('show');
                $(navbar).removeClass('open');
                $('.navbar .nav-item').removeClass('open');
                $('html').removeClass('disable-scroll');
            }
        });
        let navbtns =  document.querySelectorAll(".navbar .nav-link");
        [].forEach.call(navbtns, function (btn) {
            let target = $(btn).parent();
            btn.addEventListener("click", function (event){
                event.preventDefault();
                target.addClass('open');
            })
        });
        $('.navbar .btn-back').click(function (event){
            $('.navbar .nav-item').removeClass('open');
            $('.navbar-nav').trigger( "click" );
        });
    }

    // Wait for Images
    imagesLoaded(document.querySelector("main"), function (instance) {
        if (document.querySelectorAll(".glide-banner").length > 0) {
            let mybanner = document.getElementById("mybanner");
            if (mybanner) {
                if (mybanner.clientHeight + 260 > y)
                    scrollI.style.opacity = 1;
            }
        }
        // fadein main

        var tlinit = gsap.timeline({
            onComplete: function () {
                console.log('complete');
                $('.loader').hide();
                tlinit.play("spin");
            }
        });


        tlinit.to('.loader', {
            opacity: 0,
            easing: 'easeInOut',
            duration: 0.050
        }, '+=0');
        tlinit.to('.navbar', {
            opacity: 1,
            easing: 'easeInOut',
            duration: 0.250
        }, '+=0');
        tlinit.to('main', {
            opacity: 1,
            easing: 'easeInOut',
            duration: 0.550
        }, '+=0.200');

        tlinit.to('.banner .claim', {
            marginLeft: 0,
            opacity: 1,
            easing: 'easeInOut',
            duration: 0.50
        }, '-0.50');

        tlinit.from('.banner .claim .btn', {
            duration: 0.4,
            opacity: 0
        });


    });

    if (document.querySelectorAll("a.btn-kontakt").length > 0) {
        document.querySelectorAll("a.btn-kontakt")[0].addEventListener("click", function (event) {
            let target = document.querySelectorAll('.navbar-collapse')[0];
            if(navbartoggle.classList.contains('open')) {
                $(navbartoggle).removeClass('open');
                $(target).removeClass('show');
                $(navbar).removeClass('open');
                $('.navbar .nav-item').removeClass('open');
                $('html').removeClass('disable-scroll');
            }
        });
    }

})();

// navbar shrink
window.addEventListener("scroll", function () {
    var nav = document.getElementById("mainNav");
    if (window.scrollY > 140) {
        nav.classList.add("shrink");
        if (scrollI)
            scrollI.classList.add("hide");
    } else {
        nav.classList.remove("shrink");
    }
    if (document.querySelectorAll(".scroll-top").length > 0) {
        if (window.scrollY > 240) {
            document.querySelectorAll('.scroll-top')[0].classList.add('show');
        } else {
            document.querySelectorAll('.scroll-top')[0].classList.remove('show');
        }
    }
});

