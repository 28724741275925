/*** IMPORTS FROM imports-loader ***/
var define = false;

function setupCookieBar() {
  console.log('setupCookieBar');
  const bar = document.getElementById('privacy-cookie-bar');
  if (bar == null) return;

  const btnAccept = document.getElementById('cb-accept');
  if (btnAccept !== null)
    btnAccept.addEventListener('click', acceptCookies);

  const btnDeny = document.getElementById('cb-deny');
  if (btnDeny !== null)
    btnDeny.addEventListener('click', denyCookies);

  const state = document.getElementById('tracking-state');
  if (state !== null) {
    text = 'Tracking Cookies: ';
    state.innerHTML = (cookiesAccepted())?text +'aktiv':text +'gesperrt';
  }

  const btnOpenCB = document.getElementById('open-cookie-bar');
  if (btnOpenCB) {
    btnOpenCB.addEventListener('click', openCookieBar);
  }

  if (cbClosed() || cookiesAccepted()) return;

  bar.classList.add('fade-in');
  setTimeout(function(){ bar.classList.add('fade-in-active'); }, 1000);
}

function openCookieBar() {
  const bar = document.getElementById('privacy-cookie-bar');
  if (bar == null) return;
  bar.classList.add('fade-in');
  setTimeout(function(){ bar.classList.add('fade-in-active'); }, 10);
}

function denyCookies() {
  window._paq.push(['requireConsent']);
  window._paq.push(['optUserOut']);
  setCookie('cookies_accept=-1', true);
  hideCookieBar();
}

function acceptCookies() {
  window._paq.push(['rememberConsentGiven']);
  setCookie('cookies_accept=1');
  hideCookieBar();
}

function activateDocCheck() {
  setCookie('cookies_accept=1', true);
  hideCookieBar();
}

function hideCookieBar() {
  setCookie('cb_closed=1', true);

  const bar = document.getElementById('privacy-cookie-bar');
  if (bar == null) return;

  ['fade-in', 'fade-in-active'].forEach(function(cls) {
    bar.classList.remove(cls);
  });

  bar.classList.add('fade-out');
  setTimeout(function(){ bar.classList.add('fade-out-active'); }, 1000);

  document.location.reload(true);
}

function cbClosed() {
  return /cb_closed=1/.test(document.cookie);
}

function cookiesAccepted() {
  return /cookies_accept=1/.test(document.cookie);
}

function setCookie(cookie, sessionOnly) {
  var expiry = 0;
  sessionOnly = sessionOnly || false;
  if (!sessionOnly) {
    expiry = new Date(new Date().getTime() + 30 * 24 * 3600 * 1000).toUTCString();
  }
  document.cookie = cookie + "; path=/; expires=" + expiry;
}


document.addEventListener("DOMContentLoaded", function(event) {
  setupCookieBar();
});
/*
(function() {
  setupCookieBar();
})();
*/

